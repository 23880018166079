import get from 'lodash/get';
import { useNotificationPopup } from 'utils/hooks/useNotificationPopup';
import { useDialogs } from 'utils/hooks/useDialogs';
import useAppContext from 'utils/hooks/useAppContext';
import { useUserProfiles, useTargetProfileSubscription } from 'utils/hooks/useAuthenticatedUser';
import useGetTargetProfile from 'utils/hooks/useGetTargetProfile';
import { profileType } from 'constants/consts';
import { POPUP_TYPES, PAYWALL_TYPES } from 'constants/index';

const useArchiveLoginModal = () => {
  const { setArchiveDialog, setSwitchProfilePopUp, setSubscriptionNudgesPopup } = useNotificationPopup();
  const { setIsLoginDialog } = useDialogs();
  const { isLoggedIn } = useAppContext();
  const { profileData } = useGetTargetProfile();
  const profiles = useUserProfiles();
  const { setPaywallType } = useAppContext();
  const profileHasCastingToolAccess = profiles.find(profile => profile.hasCastingTool);
  const activeProfileSubscriptions = useTargetProfileSubscription();
  const isChurnedLoggedInUser = get(profileData, 'subscriptionStatus') === profileType.CHURNED;
  const noSubscription =
    get(profileData, 'subscriptionStatus') === (profileType.PRO || profileType.BASIC) &&
    !activeProfileSubscriptions?.[0]?.subscription?.product?.id;
  const isProProfile = get(profileData, 'subscriptionStatus') === profileType.PRO;
  const handleSubscriptionPopUp = () => {
    if (isChurnedLoggedInUser) {
      setSubscriptionNudgesPopup({
        isOpen: true,
        popupType: POPUP_TYPES.CHURNED,
      });
    } else if (noSubscription) {
      setArchiveDialog(true);
    }
  };

  const checkPermissions = () => {
    if (isLoggedIn) {
      if (profileHasCastingToolAccess) {
        setSwitchProfilePopUp(true);
      } else if (isProProfile) {
        setPaywallType(PAYWALL_TYPES.ARCHIVED_WALL);
      } else {
        setArchiveDialog(true);
      }
    } else {
      setIsLoginDialog({ isOpen: true });
    }
  };

  const onOpenLoginDialog = () =>
    isChurnedLoggedInUser || noSubscription ? handleSubscriptionPopUp() : checkPermissions();

  const handleModalOpen = e => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    onOpenLoginDialog();
  };

  return handleModalOpen;
};

export default useArchiveLoginModal;
